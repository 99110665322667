import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, Fragment as _Fragment, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecc647b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"display":"flex","position":"fixed","justify-content":"center","align-items":"center","width":"100%","height":"100%","background":"white"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginWarning = _resolveComponent("LoginWarning")!
  const _component_inbox_component = _resolveComponent("inbox-component")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    style: {"overflow":"hidden !important"},
    isChat: ""
  }, {
    default: _withCtx(() => [
      (!_ctx.accessToken)
        ? (_openBlock(), _createBlock(_component_LoginWarning, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.isInView)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  style: _normalizeStyle([{"overflow":"hidden","position":"fixed","width":"100%","text-align":"left"}, { height: _ctx.componentHeight }])
                }, [
                  (_ctx.isLoaded)
                    ? (_openBlock(), _createBlock(_component_inbox_component, {
                        key: 0,
                        deviceType: _ctx.deviceType,
                        headerHeight: _ctx.headerHeight,
                        token: _ctx.accessToken,
                        mobileHost: _ctx.mobileHost,
                        chatUserId: _ctx.chatUserId,
                        deviceToken: _ctx.websocketToken,
                        "websocket-url": _ctx.websocketUrlBase,
                        subscriberId: _ctx.store.user.subscriber_id,
                        id: "inbox",
                        onToast: _ctx.toastHandler,
                        "toast-event": _ctx.toastEvent,
                        permissions: JSON.stringify(''),
                        isApp: true,
                        userType: 'GUEST',
                        footerHeight: _ctx.footerHeight,
                        onRouterUpdate: _ctx.updateRouter,
                        scrollToBottom: _ctx.store.scrollToBottomCounter,
                        subscriberActive: _ctx.store.reservationData?.subscriber_active,
                        onCurrentConversationSubject: _ctx.updateSubject
                      }, null, 8, ["deviceType", "headerHeight", "token", "mobileHost", "chatUserId", "deviceToken", "websocket-url", "subscriberId", "onToast", "toast-event", "permissions", "footerHeight", "onRouterUpdate", "scrollToBottom", "subscriberActive", "onCurrentConversationSubject"]))
                    : _createCommentVNode("", true)
                ], 4))
              : _createCommentVNode("", true)
          ], 64)),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.initLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner, {
                  name: "crescent",
                  color: "primary"
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}