<template>
    <base-layout style="overflow: hidden !important" isChat>
        <LoginWarning v-if="!accessToken"></LoginWarning>
        <template v-else>
            <template v-if="isInView">
                <div
                    style="overflow: hidden; position: fixed; width: 100%; text-align: left"
                    :style="{ height: componentHeight }"
                >
                    <inbox-component
                        v-if="isLoaded"
                        :deviceType="deviceType"
                        :headerHeight="headerHeight"
                        :token="accessToken"
                        :mobileHost="mobileHost"
                        :chatUserId="chatUserId"
                        :deviceToken="websocketToken"
                        :websocket-url="websocketUrlBase"
                        :subscriberId="store.user.subscriber_id"
                        id="inbox"
                        @toast="toastHandler"
                        :toast-event="toastEvent"
                        :permissions="JSON.stringify('')"
                        :isApp="true"
                        :userType="'GUEST'"
                        :footerHeight="footerHeight"
                        @routerUpdate="updateRouter"
                        :scrollToBottom="store.scrollToBottomCounter"
                        :subscriberActive="store.reservationData?.subscriber_active"
                        @currentConversationSubject="updateSubject"
                    />
                </div>
            </template>
        </template>

        <transition name="fade">
            <div
                v-if="initLoading"
                style="
                    display: flex;
                    position: fixed;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    background: white;
                "
            >
                <ion-spinner name="crescent" color="primary"></ion-spinner>
            </div>
        </transition>
    </base-layout>
</template>

<script lang="ts">
    import LoginWarning from '@/components/LoginWarning.vue';
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref, watch, nextTick } from 'vue';
    import { useStore } from '@/store/store';
    import { accessToken } from '@/services/Authentication';
    import { Capacitor } from '@capacitor/core';
    import { errorToast, infoActionToast, successToast } from '@/services/ToastController';
    import AppAPI from '@/services/AppAPI';
    import { Keyboard } from '@capacitor/keyboard';
    import router from '@/router';
    import AppFunctions from '@/services/AppFunctions';
    import { showLoading, hideLoading } from '@/services/LoadingController';
    import { basicAlert } from '@/services/AlertController';

    export default defineComponent({
        components: { LoginWarning },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();
            const headerHeight = ref(0);
            const isLoaded = ref(false);
            const componentHeight = ref('100%');
            const toastEvent = ref(null);
            const footerHeight = ref(0);
            const initLoading = ref(true);

            const deviceType = computed(() => {
                return Capacitor.getPlatform();
            });
            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });
            const isInView = computed(() => {
                return router.currentRoute.value.path.includes('/tabs/messages');
            });

            const mobileHost = computed(() => {
                return store.URL_BASE;
            });

            const websocketUrlBase = computed(() => {
                let tmp = store.URL_BASE;
                tmp = tmp.replace('https://', 'wss://');
                tmp = tmp + store.user.websocket_url;
                return tmp;
            });

            const chatUserId = computed(() => {
                return store.user.chat_user_id;
            });

            const websocketToken = computed(() => {
                return store.user.websocket_token;
            });

            const emitKeyboard = (e: any) => {
                console.log('keyboard', e);
                componentHeight.value = '100%';
            };

            const load = () => {
                nextTick(function () {
                    setTimeout(() => {
                        const element = document.getElementById('inbox');
                        const rect = element?.getBoundingClientRect();
                        headerHeight.value = rect?.top || 0;
                        footerHeight.value = document.getElementById('footer')?.clientHeight || 0;

                        if (headerHeight.value > 0 && initLoading.value) {
                            initLoading.value = false;
                        }
                    }, 100);
                });
            };

            const getData = async () => {
                const user = (await AppAPI.getUser()).data;
                if (user) {
                    store.user = user;
                }
            };

            const toastHandler = async (data: any) => {
                if (data.detail[0].mode === 'success') {
                    await successToast(data.detail[0].message);
                } else if (data.detail[0].mode === 'error') {
                    await errorToast(data.detail[0].message);
                } else {
                    await infoActionToast(data.detail[0].message, 'View', () => {
                        toastEvent.value = data.detail[0].conversationId;
                    });
                }
            };

            const updateRouter = async (data: any) => {
                if (data.detail[0] === '/' || data.detail[0].includes('/conversation/')) {
                    ionRouter.replace('/tabs/messages' + data.detail[0]);
                    console.log('updateRouter', data.detail[0]);
                }
            };

            const updateSubject = async (data: any) => {
                store.currentConversationSubject = data.detail[0];
            };

            onMounted(async () => {
                initLoading.value = true;
                try {
                    if (Capacitor.getPlatform() != 'web') {
                        Keyboard.addListener('keyboardWillShow', (info) => {
                            footerHeight.value = 0;
                        });

                        Keyboard.addListener('keyboardDidHide', async () => {
                            footerHeight.value = document.getElementById('footer')?.clientHeight || 0;
                            if (footerHeight.value == 0) {
                                setTimeout(() => {
                                    footerHeight.value = document.getElementById('footer')?.clientHeight || 0;
                                }, 250);
                            }
                        });
                    }
                    if (store.reservationData?.conversation_id) {
                        if (
                            router.currentRoute.value.params.pathMatch[1]?.toString() !=
                                store.reservationData.conversation_id ||
                            store.viewingChatWithoutReservation
                        ) {
                            try {
                                let id = '';
                                if (store.allReservations.length <= 0) {
                                    const loadingRes = await showLoading('Loading Reservation...');
                                    try {
                                        await AppFunctions.refreshReservationList();
                                    } catch (e) {
                                        console.log(e);
                                    } finally {
                                        hideLoading(loadingRes);
                                    }
                                }
                                for (let i = 0; i < store.allReservations.length; i++) {
                                    if (
                                        store.allReservations[i].conversation_id ==
                                        router.currentRoute.value.params.pathMatch[1]?.toString()
                                    ) {
                                        id = store.allReservations[i].id;
                                        break;
                                    }
                                }
                                if (id) {
                                    await store.changeSelectedReservationId(id);
                                    const reservation = (await AppAPI.getReservationData(store.selectedReservationId))
                                        .data;
                                    store.reservationData = reservation;
                                    store.selectedTechReservationId = reservation.tech_reservations[0].id;
                                    await AppFunctions.getHostedForms();
                                    store.viewingChatWithoutReservation = false;
                                } else {
                                    if (!store.viewingChatWithoutReservation) {
                                        //alert('Alert: There is no invoice found for this conversation.')
                                    }
                                    store.noReservationChatConversationId =
                                        router.currentRoute.value.params.pathMatch[1]?.toString();
                                    store.viewingChatWithoutReservation = true;
                                }
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    }
                    await getData();
                    if (AppFunctions.isImpersonating()) {
                        basicAlert('Alert', '', 'Inbox is not available for your account.');
                        router.replace('/tabs/');
                    }
                    load();
                    if (store.user.chat_user_id) {
                        if (process.env.VUE_APP_INBOX_LIVE_RELOAD === 'true' && store.isLocalDevelopment) {
                            let link = document.createElement('link');
                            link.rel = 'stylesheet';
                            link.href = 'http://localhost:5173/src/styles/main.css';
                            document.head.appendChild(link);

                            link = document.createElement('link');
                            link.rel = 'stylesheet';
                            link.href = `unified-inbox/assets/fonts/lato/lato.css`;
                            document.head.appendChild(link);

                            const script = document.createElement('script');
                            script.type = 'module';
                            script.src = 'http://localhost:5173/src/main.ce.ts';
                            document.body.appendChild(script);
                        } else {
                            let link = document.createElement('link');
                            link.rel = 'stylesheet';
                            link.href = 'unified-inbox/style.css';
                            document.head.appendChild(link);

                            link = document.createElement('link');
                            link.rel = 'stylesheet';
                            link.href = `unified-inbox/assets/fonts/lato/lato.css`;
                            document.head.appendChild(link);

                            const script = document.createElement('script');
                            script.type = 'module';
                            script.src = `unified-inbox/inbox-component.js`;
                            script.onerror = () => {
                                console.error('Failed to load inbox-component script');
                            };
                            document.body.appendChild(script);
                        }
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        isLoaded.value = true;
                        load();
                    }
                } catch (e) {
                    console.log(e);
                    basicAlert('Alert', '', 'There was an issue loading messages. Please try again.');
                    router.replace('/tabs/');
                } finally {
                    setTimeout(() => {
                        if (initLoading.value) {
                            initLoading.value = false;
                        }
                    }, 1000 * 10); // 10 seconds
                }
            });

            return {
                ionRouter,
                isInView,
                deviceType,
                store,
                accessToken,
                selectedProperty,
                headerHeight,
                footerHeight,
                componentHeight,
                isLoaded,
                toastEvent,
                mobileHost,
                websocketUrlBase,
                chatUserId,
                websocketToken,
                initLoading,
                emitKeyboard,
                load,
                getData,
                toastHandler,
                updateRouter,
                updateSubject,
            };
        },
    });
</script>

<style scoped>
    ion-spinner {
        width: 100px;
        height: 100px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-to {
        opacity: 1;
    }
</style>
