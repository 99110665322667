import axios from 'axios';
import { accessToken, refreshToken, refresh, logout } from '@/services/Authentication';
import { useStore } from '@/store/store';

export default () => {
    const store = useStore();

    const api = axios.create({
        baseURL: store.URL_BASE + '/guestapp',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    api.interceptors.request.use(async (config) => {
        if (accessToken.value) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            config.headers!.Authorization = `Bearer ${accessToken.value}`;
        }

        return config;
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.config.url === '/authentication/token/refresh/') {
                logout(false, true);
            }
            if (error.response.status === 403 && !originalRequest._retry) {
                //401
                originalRequest._retry = true;
                if (!refreshToken.value) {
                    return Promise.reject(error);
                }
                try {
                    const didRefresh = await refresh();
                    if (!didRefresh) {
                        logout(false, true);
                        return Promise.reject(error);
                    }
                    originalRequest.headers.Authorization = `Bearer ${accessToken.value}`;
                    return api(originalRequest);
                } catch (e) {
                    logout(false, true);
                    return Promise.reject(e);
                }
            }
            return Promise.reject(error);
        }
    );

    return api;
};
