<template>
    <modal-base-layout showPropertySegment title="Property Details">
        <transition name="v">
            <div v-if="refreshItemView">
                <div style="margin: 15px">
                    <div
                        style="
                            border-radius: 10px;
                            box-sizing: border-box;
                            box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
                            overflow: hidden !important;
                            aspect-ratio: 1/1;
                        "
                    >
                        <div v-if="refreshItemView">
                            <PropertyImageSwiper :show-pagination="true" :auto-play="true" isSingleProperty />
                        </div>
                        <div v-if="!refreshItemView" style="width: 100%; height: 100%"></div>
                    </div>
                </div>

                <DescriptionCard />

                <PropertyAddressDirections />

                <WifiDetails />

                <DoorCode />

                <HouseManual />

                <OccupancyDetails />

                <div v-if="accommodationItems.length > 0 && refreshItemView">
                    <div class="section-header" style="margin-top: 10px">
                        <ion-label class="section-title">Accommodations</ion-label>
                    </div>
                    <swiper-container class="propertyDetails" :slides-per-view="'1.5'">
                        <template v-for="(item, index) in accommodationItems" :key="index">
                            <swiper-slide>
                                <ion-card style="min-height: 100px; padding: 5px">
                                    <ion-card-content>
                                        <ion-label style="font-weight: bold">{{ item.label }}</ion-label>
                                        <div style="display: flex; flex-direction: column; padding-left: 5px">
                                            <template v-for="(beds, bedIndex) in item.beds" :key="bedIndex">
                                                <ion-label style="padding-top: 2.5px; padding-bottom: 2.5px"
                                                    >{{ beds.qty }} - {{ beds.name }}</ion-label
                                                >
                                            </template>
                                            <template
                                                v-for="(bathroom, bathIndex) in item.bathroom_features"
                                                :key="bathIndex"
                                            >
                                                <ion-label style="padding-top: 2.5px; padding-bottom: 2.5px"
                                                    >{{ bathroom.qty }} - {{ bathroom.name }}</ion-label
                                                >
                                            </template>
                                        </div>
                                    </ion-card-content>
                                </ion-card>
                            </swiper-slide>
                        </template>
                    </swiper-container>
                </div>

                <ion-card
                    v-if="Capacitor.getPlatform() == 'ios' && selectedProperty.rental_property.amenities.length > 0"
                >
                    <ion-card-header>
                        <ion-label class="section-title">Amenities</ion-label>
                    </ion-card-header>

                    <ion-card-content style="margin: 10px 0">
                        <ion-list lines="full">
                            <template
                                v-for="amenity in selectedProperty.rental_property.amenities.slice(0, 4)"
                                :key="amenity"
                            >
                                <ion-item>
                                    <ion-label>{{ amenity.name }}</ion-label>
                                </ion-item>
                            </template>
                        </ion-list>
                        <ion-button
                            size="small"
                            style="width: 100%; padding: 10px 15px 0px; margin: 0px"
                            @click="amenitiesSearchModal()"
                            v-if="selectedProperty.rental_property.amenities.length > 4"
                        >
                            View All
                            <ion-icon :icon="chevronForward" slot="end" />
                        </ion-button>
                    </ion-card-content>
                </ion-card>

                <PropertyCustomVariables :location="1" />

                <CancellationPolicyCard />

                <div style="margin-top: 50px"></div>
            </div>
        </transition>
    </modal-base-layout>
</template>

<script lang="ts">
    import { modalController, useIonRouter } from '@ionic/vue';
    import { closeOutline, copyOutline, openOutline, star, chevronForward } from 'ionicons/icons';
    import { computed, defineComponent, onMounted, ref, watch } from 'vue';
    import { useStore } from '@/store/store';
    import { basicAlert } from '@/services/AlertController';
    import { advancedActionSheet } from '@/services/ActionController';
    import AppFunctions from '@/services/AppFunctions';
    import PropertyImageSwiper from '@/components/SelectedPropertyComponents/PropertyImageSwiper.vue';
    import { Capacitor } from '@capacitor/core';
    import WifiDetails from '@/components/SelectedPropertyComponents/WifiDetails.vue';
    import PropertyAddressDirections from '@/components/SelectedPropertyComponents/PropertyAddressDirections.vue';
    import DescriptionCard from '@/components/SelectedPropertyComponents/DescriptionCard.vue';
    import HouseManual from '@/components/SelectedPropertyComponents/HouseManual.vue';
    import OccupancyDetails from '@/components/SelectedPropertyComponents/OccupancyDetails.vue';
    import { virtualTourModal, amenitiesSearchModal } from '@/services/ModalController';
    import DoorCode from '@/components/SelectedPropertyComponents/DoorCode.vue';
    import PropertyCustomVariables from '@/components/SelectedPropertyComponents/PropertyCustomVariables.vue';
    import CancellationPolicyCard from '../SelectedPropertyComponents/CancellationPolicyCard.vue';

    export default defineComponent({
        components: {
            PropertyImageSwiper,
            WifiDetails,
            PropertyAddressDirections,
            DescriptionCard,
            DoorCode,
            HouseManual,
            OccupancyDetails,
            PropertyCustomVariables,
            CancellationPolicyCard,
        },
        setup() {
            const ionRouter = useIonRouter();
            const store = useStore();
            const refreshItemView = ref(true);
            const isShortDescription = ref(
                AppFunctions.getSelectedPropertyData().rental_property.description.length > 200
            );
            const descriptionText = computed(() => {
                if (
                    AppFunctions.getSelectedPropertyData().rental_property.description.length > 200 &&
                    isShortDescription.value
                ) {
                    return AppFunctions.getSelectedPropertyData().rental_property.description.substring(0, 200) + '...';
                } else {
                    return AppFunctions.getSelectedPropertyData().rental_property.description;
                }
            });

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const accommodationItems = computed(() => {
                let items = selectedProperty.value.rental_property.places;

                for (const item of items) {
                    if (item.number_of_beds == 0 && item.number_of_bathroom_features == 0) {
                        items = items.filter((i: any) => i != item);
                    }
                }

                return items;
            });

            onMounted(async () => {
                await AppFunctions.refreshCurrentReservationData();
            });

            watch(
                () => store.selectedTechReservationId,
                () => {
                    refreshItemView.value = false;
                    isShortDescription.value =
                        AppFunctions.getSelectedPropertyData().rental_property.description.length > 200;
                    setTimeout(function () {
                        refreshItemView.value = true;
                    }, 10);
                }
            );

            return {
                ionRouter,
                closeOutline,
                store,
                openOutline,
                star,
                advancedActionSheet,
                copyOutline,
                AppFunctions,
                selectedProperty,
                refreshItemView,
                isShortDescription,
                descriptionText,
                basicAlert,
                Capacitor,
                chevronForward,
                accommodationItems,
                virtualTourModal,
                amenitiesSearchModal,
            };
        },
    });
</script>
<style scoped>
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
</style>
