<template>
    <ion-app>
        <side-menu />
        <ion-router-outlet id="main-content" />
    </ion-app>
</template>

<script lang="ts">
    import { IonApp, IonRouterOutlet, useIonRouter } from '@ionic/vue';
    import { defineComponent, nextTick, onBeforeMount, onMounted, ref, watch } from 'vue';
    import { addPushNotificationTapOpen, handlePushNotificationOpen } from '@/services/FCM';
    import { useStore } from '@/store/store';
    import { Capacitor } from '@capacitor/core';
    import { BranchDeepLinks, BranchInitEvent } from 'capacitor-branch-deep-links';
    import { accessToken, login } from '@/services/Authentication';
    import { startup } from '@/services/Startup';
    import { SplashScreen } from '@capacitor/splash-screen';
    import AppAPI from '@/services/AppAPI';
    import { Device } from '@capacitor/device';
    import SideMenu from '@/components/SideMenu.vue';
    import { App } from '@capacitor/app';
    import AppFunctions from '@/services/AppFunctions';
    import router from './router';
    import { checkForAppUpdate } from '@/services/AppUpdateController';
    import { updateNotificationPermissionStatus } from '@/services/Utils';
    import { Network } from '@capacitor/network';
    import { offlineModal } from '@/services/ModalController';
    import { VpnDetector } from 'capacitor-vpn-detector';
    import { basicAlert } from './services/AlertController';

    export default defineComponent({
        name: 'App',
        components: {
            IonApp,
            IonRouterOutlet,
            SideMenu,
        },
        setup() {
            const store = useStore();
            addPushNotificationTapOpen();
            checkForAppUpdate();
            const ionRouter = useIonRouter();
            const allowOpenReport = ref(true);

            App.addListener('appStateChange', async (state) => {
                if (state.isActive) {
                    try {
                        await AppFunctions.refreshCurrentReservationData();
                        const user = (await AppAPI.getUser()).data;
                        if (user) {
                            store.user = user;
                        }
                        await updateNotificationPermissionStatus();
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    //code to run when app closes into background
                }
            });

            onMounted(async () => {
                // check network
                const status = await Network.getStatus();
                store.isOffline = !status.connected;

                if (store.isOffline) {
                    offlineModal();
                    setTimeout(() => {
                        SplashScreen.hide();
                    }, 1000);
                    return;
                }

                allowOpenReport.value = true;
                window.addEventListener('notificationTapped', async (e: any) => {
                    if (allowOpenReport.value) {
                        allowOpenReport.value = false;
                        AppAPI.setPushNotificationOpened();
                        handlePushNotificationOpen(e);

                        setTimeout(() => {
                            allowOpenReport.value = true;
                        }, 5000);
                    }
                });
                if (Capacitor.getPlatform() != 'web') {
                    if (!accessToken.value) {
                        const vpnActive = await VpnDetector.isVpnActive();
                        if (vpnActive.value) {
                            basicAlert('VPN Detected', '', 'Please disable your VPN and try again.');
                        }
                    }
                    BranchDeepLinks.addListener('init', async (event: BranchInitEvent) => {
                        if (event.referringParams.token && accessToken.value == '') {
                            if (Capacitor.getPlatform() != 'web') {
                                const vpnActive = await VpnDetector.isVpnActive();
                                if (vpnActive.value) {
                                    basicAlert('VPN Detected', '', 'Please disable your VPN and try again.');
                                }
                            }
                            const isLoggedIn = await login(event.referringParams.token);
                            if (isLoggedIn) {
                                ionRouter.replace('/tabs/');
                            }
                        }

                        if (
                            event.referringParams.conversation_id &&
                            event.referringParams.message_id &&
                            accessToken.value
                        ) {
                            await SplashScreen.show();
                            try {
                                if (router.currentRoute.value.path.includes('/tabs/messages/conversation/')) {
                                    ionRouter.replace(
                                        '/tabs/reservation/redirect?url=/tabs/messages/conversation/' +
                                            event.referringParams.conversation_id +
                                            '?message_id=' +
                                            event.referringParams.message_id
                                    );
                                } else {
                                    ionRouter.replace(
                                        '/tabs/messages/conversation/' +
                                            event.referringParams.conversation_id +
                                            '?message_id=' +
                                            event.referringParams.message_id
                                    );
                                }
                            } catch (e) {
                                console.error(e);
                                SplashScreen.hide();
                            }
                        }
                    });

                    BranchDeepLinks.addListener('initError', (error: any) => {
                        console.error('branch deep link error:', JSON.stringify(error));
                    });
                }

                if (accessToken.value && Capacitor.getPlatform() != 'web') {
                    await startup();
                } else {
                    SplashScreen.hide();
                }
            });

            if (accessToken.value && Capacitor.getPlatform() == 'web') {
                startup(false);
            }

            watch(accessToken, async (newValue, oldValue) => {
                if (newValue && !oldValue) {
                    await startup();
                }
            });

            const getDeviceModel = async () => {
                const deviceInfo = await Device.getInfo();
                const deviceModel = deviceInfo.model;
                return deviceModel;
            };

            const getDevice = async () => {
                const deviceInfo = await Device.getInfo();
                const tmpDevice = await AppAPI.getDeviceById(store.pushNotificationToken).catch((e: any) => {
                    console.log(e);
                });

                let deviceName = deviceInfo.name;
                if (tmpDevice?.data?.name) {
                    deviceName = tmpDevice.data.name;
                }
                if (deviceName == undefined) {
                    deviceName = 'Unknonwn Device';
                }

                const deviceData = {
                    name: deviceName,
                    active: true,
                };

                return deviceData;
            };

            watch(
                () => store.pushNotificationToken,
                async (newValue) => {
                    if (newValue && Capacitor.getPlatform() != 'web' && accessToken.value) {
                        const model = await getDeviceModel();
                        const device = await getDevice();
                        await updateNotificationPermissionStatus();

                        await AppAPI.createDevice(
                            device.name,
                            store.pushNotificationToken,
                            model,
                            Capacitor.getPlatform(),
                            device.active
                        ).catch((e: any) => {
                            console.log(e);
                        });
                    }
                }
            );

            watch(
                () => store.user,
                async (newValue) => {
                    if (newValue) {
                        //
                    }
                }
            );

            watch(
                () => store.reservationData,
                async (newValue) => {
                    if (newValue) {
                        await AppAPI.logStatistics().catch((e: any) => {
                            console.log(e);
                        });
                    }
                }
            );

            return {
                ionRouter,
                store,
                close,
            };
        },
    });
</script>

<style scoped>
    ion-header {
        --background: var(--background-color);
    }
</style>
