<template>
    <modal-base-layout title="Amenities">
        <template #header>
            <ion-searchbar
                placeholder="Search"
                :debounce="250"
                @ionInput="search($event)"
                show-clear-button="focus"
                style="text-align: left; padding: 0px"
            />
        </template>

        <ion-spinner v-if="isLoading" name="crescent" style="margin-top: 50px"></ion-spinner>
        <div v-else>
            <ion-list lines="full">
                <template v-for="result in resultEmbedding" :key="result.amenity">
                    <ion-item>
                        <ion-label>{{ result.amenity }}</ion-label>
                        <ion-note slot="end" v-if="false">{{ result.similarity }}</ion-note>
                    </ion-item>
                </template>
            </ion-list>
        </div>
    </modal-base-layout>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import { showLoading, hideLoading } from '@/services/LoadingController';

    import * as use from '@tensorflow-models/universal-sentence-encoder';
    import * as tf from '@tensorflow/tfjs';
    import AppFunctions from '@/services/AppFunctions';
    import { errorToast } from '@/services/ToastController';

    export default defineComponent({
        setup(props) {
            const store = useStore();
            const ionRouter = useIonRouter();
            let modal: any;
            const isLoading = ref(false);
            const resultEmbedding = ref([] as any);
            const amenitiesEmbeddings = ref([] as any);

            const loadModel = async () => {
                try {
                    tf.setBackend('cpu'); // or 'cpu', or 'wasm'
                    await tf.ready();
                    console.log('TensorFlow.js is ready!');
                    modal = await use.load();
                    console.log('Model loaded');
                } catch (error) {
                    console.error('Error loading model:', error);
                }
            };

            const search = async (event: any) => {
                isLoading.value = true;
                try {
                    const text = event.detail.value;
                    resultEmbedding.value = await generateEmbedding(modal, text);
                    const searchResults = await searchEmbeddings(resultEmbedding.value, text);
                    resultEmbedding.value = searchResults;
                } catch (error) {
                    console.error('Error searching:', error);
                } finally {
                    isLoading.value = false;
                }
            };

            const searchEmbeddings = async (queryEmbedding: any, text: string) => {
                return amenitiesEmbeddings.value
                    .map((amenity: any) => ({
                        amenity: amenity.amenity,
                        similarity: cosineSimilarity(queryEmbedding, amenity.embedding),
                    }))
                    .sort((a: any, b: any) => b.similarity - a.similarity)
                    .filter((a: any) => {
                        if (!text) {
                            return true;
                        }
                        return a.similarity > 0.45;
                    });
            };

            const generateEmbedding = async (modal: any, text: string) => {
                try {
                    isLoading.value = true;
                    if (modal) {
                        const embeddings = await modal.embed(text);
                        const array = await embeddings.array();
                        return array[0];
                    } else {
                        console.error('modal.value is not defined');
                    }
                } catch (error) {
                    console.error('Error generating embeddings:', error);
                } finally {
                    isLoading.value = false;
                }
            };

            const loadAmenityEmbeddings = async () => {
                let savedData;
                try {
                    savedData = await store.getAmenitiesEmbeddingData();
                } catch (error) {
                    console.error('Error loading amenity embeddings:', error);
                }

                try {
                    const amenities = AppFunctions.getSelectedPropertyData().rental_property.amenities;

                    if (savedData) {
                        if (savedData.length === amenities.length) {
                            let isSame = true;
                            for (let i = 0; i < amenities.length; i++) {
                                if (savedData[i].amenity !== amenities[i].name) {
                                    isSame = false;
                                    break;
                                }
                            }
                            if (isSame) {
                                amenitiesEmbeddings.value = savedData;
                                resultEmbedding.value = amenitiesEmbeddings.value;
                                return;
                            }
                        }
                    }

                    for (let i = 0; i < amenities.length; i++) {
                        const text = amenities[i].name;
                        const embedding = await generateEmbedding(modal, text);
                        if (embedding) {
                            amenitiesEmbeddings.value.push({
                                amenity: text,
                                embedding: embedding,
                            });
                        }
                    }
                    resultEmbedding.value = amenitiesEmbeddings.value;
                    await store.setAmenitiesEmbeddingData(amenitiesEmbeddings.value);
                } catch (error) {
                    console.error('Error loading amenity embeddings:', error);
                }
            };

            const cosineSimilarity = (vecA: any, vecB: any) => {
                const dotProduct = vecA.reduce((sum: any, a: any, idx: any) => sum + a * vecB[idx], 0);
                const magnitudeA = Math.sqrt(vecA.reduce((sum: any, a: any) => sum + a * a, 0));
                const magnitudeB = Math.sqrt(vecB.reduce((sum: any, b: any) => sum + b * b, 0));
                return dotProduct / (magnitudeA * magnitudeB);
            };

            onMounted(async () => {
                const loading = await showLoading('Loading...');
                try {
                    await loadModel();
                    await loadAmenityEmbeddings();
                } catch (error) {
                    console.error('Error loading amenity embeddings:', error);
                    errorToast('Error loading amenity embeddings');
                } finally {
                    hideLoading(loading);
                }
            });

            return {
                ionRouter,
                store,
                isLoading,
                search,
                resultEmbedding,
                amenitiesEmbeddings,
            };
        },
    });
</script>

<style scoped></style>
