<template>
    <base-layout>
        <LoginWarning v-if="!accessToken"></LoginWarning>
        <div class="content" v-else-if="!store.viewingChatWithoutReservation && store.selectedReservationId">
            <ion-card style="margin: 15px">
                <ion-card-content>
                    <div
                        style="
                            display: flex;
                            justify-content: space-evenly;
                            text-align: center;
                            margin: 10px;
                            align-items: center;
                        "
                    >
                        <div style="margin-right: 30px">
                            <ion-label style="font-weight: bold; font-size: 18px">Check-In</ion-label>
                            <p style="font-size: 16px; color: #4a4a4a">
                                {{ formatDate(AppFunctions.getSelectedPropertyData().check_in_dt) }}
                            </p>
                            <p style="font-size: 16px; color: #4a4a4a">
                                {{ formatTime(AppFunctions.getSelectedPropertyData().check_in_dt) }}
                            </p>
                        </div>
                        <ion-icon style="width: 50px; height: 50px" color="primary" :src="calendar"></ion-icon>
                        <div style="margin-left: 30px">
                            <ion-label style="font-weight: bold; font-size: 18px">Check-Out</ion-label>
                            <p style="font-size: 16px; color: #4a4a4a">
                                {{ formatDate(AppFunctions.getSelectedPropertyData().check_out_dt) }}
                            </p>
                            <p style="font-size: 16px; color: #4a4a4a">
                                {{ formatTime(AppFunctions.getSelectedPropertyData().check_out_dt) }}
                            </p>
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>

            <ion-card>
                <ion-card-content style="margin: 10px 15px">
                    <ion-list lines="full">
                        <ion-item @click="propertyDetailsModal()" style="cursor: pointer">
                            <ion-icon color="primary" :src="home" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Property Details</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item @click="arrivalModal()" style="cursor: pointer">
                            <ArrivalIcon
                                style="
                                    margin-right: 10px;
                                    stroke: var(--ion-color-primary);
                                    fill: var(--ion-color-primary);
                                "
                            />
                            <ion-label style="font-weight: bold; padding: 5px">Arrival Info</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item @click="departureModal()" style="cursor: pointer">
                            <DepartureIcon style="fill: var(--ion-color-primary); margin-right: 10px" />
                            <ion-label style="font-weight: bold; padding: 5px">Departure Info</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            style="cursor: pointer"
                            @click="rentalAgreementModal()"
                            v-if="
                                (store.user.staylio_app_settings?.allow_rental_agreements ||
                                    store.reservationData.sign_allowed) &&
                                store.reservationData.sign_status != 'N/A' &&
                                !AppFunctions.isImpersonating()
                            "
                        >
                            <ion-icon :icon="documentText" color="primary" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Rental Agreement</ion-label>
                            <ion-icon
                                v-if="
                                    store.reservationData.sign_status != 'SIGNED' && store.reservationData.sign_allowed
                                "
                                :icon="alertCircle"
                                color="danger"
                            ></ion-icon>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            @click="paymentModal()"
                            style="cursor: pointer"
                            v-if="!AppFunctions.isImpersonating()"
                        >
                            <ion-icon color="primary" :src="card" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Payments</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            @click="hostedFormsListModal()"
                            v-if="store.hostedForms.length >= 0 && !AppFunctions.isImpersonating()"
                            style="cursor: pointer"
                        >
                            <ion-icon :icon="alertCircle" color="primary" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Uncompleted Forms</ion-label>
                            <ion-badge color="danger" v-if="store.hostedForms.length > 0">{{
                                store.hostedForms.length
                            }}</ion-badge>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            @click="guestVerifyModal()"
                            style="cursor: pointer"
                            v-if="!AppFunctions.isImpersonating()"
                        >
                            <ion-icon :icon="idCard" color="primary" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Guest Verification</ion-label>
                            <ion-icon
                                v-if="!store.user.is_verified && store.user.is_verification_enabled"
                                :icon="alertCircle"
                                color="danger"
                            ></ion-icon>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            @click="sharedUsersListModal()"
                            style="cursor: pointer"
                            v-if="!AppFunctions.isImpersonating()"
                        >
                            <ion-icon :icon="shareSocial" color="primary" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Shares</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            style="cursor: pointer"
                            @click="guestReviewsModal()"
                            v-if="
                                store.user.staylio_app_settings?.allow_guest_reviews && !AppFunctions.isImpersonating()
                            "
                        >
                            <ion-icon color="primary" :src="star" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Leave a Review</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            style="cursor: pointer"
                            @click="notificationListModal()"
                            :lines="!store.reservationData.subscriber_active ? 'none' : 'full'"
                            v-if="!AppFunctions.isImpersonating()"
                        >
                            <ion-icon color="primary" :src="notifications" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Notifications</ion-label>
                            <ion-badge color="danger" v-if="store.user?.unread_notification_count">{{
                                store.user?.unread_notification_count
                            }}</ion-badge>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item
                            style="cursor: pointer"
                            lines="none"
                            @click="contactUsModal()"
                            v-if="store.reservationData.subscriber_active"
                        >
                            <ion-icon color="primary" :src="informationCircle" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">Contact Us</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>

                        <ion-item v-if="false" @click="duringStayModal()" style="cursor: pointer">
                            <ion-icon color="primary" :src="map" style="margin-right: 10px"></ion-icon>
                            <ion-label style="font-weight: bold; padding: 5px">During Your Stay</ion-label>
                            <ion-icon :icon="chevronForward" color="primary"></ion-icon>
                        </ion-item>
                    </ion-list>
                </ion-card-content>
            </ion-card>
        </div>
        <div v-else>
            <ion-card>
                <ion-card-header>
                    <ion-label class="section-title">No Stay Selected</ion-label>
                </ion-card-header>
                <ion-card-content>
                    <ion-list lines="none">
                        <ion-item>
                            <ion-label v-if="store.viewingChatWithoutReservation">
                                You are viewing a conversation without a stay. Please select a conversation with a stay
                                or select a stay below to view more details.
                            </ion-label>
                            <ion-label v-else>
                                You have not selected a stay. Please select a stay to view more details.
                            </ion-label>
                        </ion-item>
                        <div style="width: 100%; justify-content: center; display: flex; margin-bottom: 15px">
                            <ion-button @click="swapReservationModal()">Select A Stay</ion-button>
                        </div>
                    </ion-list>
                </ion-card-content>
            </ion-card>
        </div>
        <div style="margin: 20px" />
    </base-layout>
</template>

<script lang="ts">
    import LoginWarning from '@/components/LoginWarning.vue';
    import { useIonRouter } from '@ionic/vue';
    import { computed, defineComponent, onMounted, ref } from 'vue';
    import { useStore } from '@/store/store';
    import {
        bonfire,
        home,
        informationCircle,
        card,
        calendar,
        map,
        alertCircle,
        chevronForward,
        documentText,
        notifications,
        star,
        document,
        idCard,
        peopleCircle,
        shareSocial,
        shareSocialOutline,
    } from 'ionicons/icons';
    import {
        arrivalModal,
        departureModal,
        policiesModal,
        duringStayModal,
        paymentModal,
        propertyDetailsModal,
        swapReservationModal,
        hostedFormsListModal,
        notificationListModal,
        contactUsModal,
        rentalAgreementModal,
        guestReviewsModal,
        guestVerifyModal,
        sharedUsersListModal,
    } from '@/services/ModalController';
    import { accessToken, refreshToken } from '@/services/Authentication';
    import { formatTime, formatDate } from '@/services/Date';
    import AppFunctions from '@/services/AppFunctions';
    import { InAppReview } from '@capacitor-community/in-app-review';
    import { format } from 'date-fns';
    import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
    import ArrivalIcon from '@/assets/icons/ArrivalIcon.vue';
    import DepartureIcon from '@/assets/icons/DepartureIcon.vue';
    import router from '@/router';
    import { SplashScreen } from '@capacitor/splash-screen';
    import { Capacitor } from '@capacitor/core';
    import { checkForAppUpdate } from '@/services/AppUpdateController';

    export default defineComponent({
        components: {
            LoginWarning,
            ArrivalIcon,
            DepartureIcon,
        },
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const screenSize = ref({
                height: window.innerHeight,
                width: window.innerWidth,
            });

            const selectedProperty = computed(() => {
                return AppFunctions.getSelectedPropertyData();
            });

            const resizeWindow = () => {
                screenSize.value = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            };

            const rateApp = async () => {
                await InAppReview.requestReview();
                const year = format(new Date(), 'yyyy');
                store.updateReviewStatus(year);
            };

            const notificationCount = computed(() => {
                if (!store.pushNotificationList) {
                    return 0;
                }
                return store.pushNotificationList?.filter((item) => !item.read_status).length;
            });

            onMounted(async () => {
                resizeWindow();
                window.addEventListener('resize', resizeWindow);

                if (router.currentRoute.value.fullPath.includes('/tabs/reservation/redirect')) {
                    const url = router.currentRoute.value.fullPath.split('/tabs/reservation/redirect?url=')[1];
                    setTimeout(async () => {
                        ionRouter.replace(url);
                        await SplashScreen.hide();
                    }, 100);
                }

                if (Capacitor.getPlatform() != 'web') {
                    const reviewStatus = await SecureStoragePlugin.get({ key: 'reviewStatus' }).catch((error) => {
                        console.log('reviewStatus:', error);
                    });

                    if (reviewStatus?.value) {
                        await store.updateReviewStatus(reviewStatus.value);
                    }

                    const pushNotificationReminderState = await SecureStoragePlugin.get({
                        key: 'pushNotificationReminderState',
                    }).catch((error) => {
                        console.log('pushNotificationReminderState:', error);
                    });

                    if (pushNotificationReminderState?.value) {
                        if (pushNotificationReminderState.value == 'true') {
                            await store.updatepushNotificationReminderHidden(true);
                        } else {
                            await store.updatepushNotificationReminderHidden(false);
                        }
                    }
                }

                if (
                    accessToken.value &&
                    refreshToken.value &&
                    (store.reviewStatus == '' ||
                        store.reviewStatus == null ||
                        parseInt(store.reviewStatus) < parseInt(format(new Date(), 'yyyy'))) &&
                    Capacitor.getPlatform() != 'web'
                ) {
                    rateApp();
                }

                if (
                    accessToken.value &&
                    !(await AppFunctions.checkPushNotificationPermission()) &&
                    !store.hasPushNotificationReminderSent &&
                    !store.pushNotificationReminderHidden
                ) {
                    //await pushNotificationReminderModal();
                } else if (accessToken.value && (await AppFunctions.checkPushNotificationPermission())) {
                    await store.updatepushNotificationReminderHidden(false);
                }
            });

            return {
                ionRouter,
                store,
                home,
                bonfire,
                informationCircle,
                card,
                map,
                calendar,
                accessToken,
                AppFunctions,
                screenSize,
                alertCircle,
                chevronForward,
                documentText,
                notifications,
                document,
                star,
                selectedProperty,
                idCard,
                peopleCircle,
                shareSocial,
                shareSocialOutline,
                notificationCount,
                rentalAgreementModal,
                guestReviewsModal,
                notificationListModal,
                hostedFormsListModal,
                guestVerifyModal,
                formatTime,
                formatDate,
                paymentModal,
                arrivalModal,
                departureModal,
                policiesModal,
                duringStayModal,
                swapReservationModal,
                propertyDetailsModal,
                contactUsModal,
                sharedUsersListModal,
            };
        },
    });
</script>

<style scoped>
    ion-badge {
        color: white;
    }
</style>
