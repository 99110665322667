import { ref } from 'vue';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import AppAPI from './AppAPI';
import router from '@/router';
import { errorToast, successToast } from './ToastController';
import { useStore } from '@/store/store';
import { SplashScreen } from '@capacitor/splash-screen';
import { basicAlert } from './AlertController';
import { hideLoading, showLoading } from './LoadingController';
import { VpnDetector } from 'capacitor-vpn-detector';
import { Capacitor } from '@capacitor/core';

export const accessToken = ref(
    (
        await SecureStoragePlugin.get({ key: 'accessToken' }).catch((error) => {
            console.log('accessToken:', error);
        })
    )?.value || ''
);
export const refreshToken = ref(
    (
        await SecureStoragePlugin.get({ key: 'refreshToken' }).catch((error) => {
            console.log('refreshToken:', error);
        })
    )?.value || ''
);

export async function updateAuthTokens(newAccessToken: any, newRefreshToken: any) {
    await SecureStoragePlugin.set({
        key: 'accessToken',
        value: newAccessToken,
    }).catch((error) => {
        console.log(error);
    });

    await SecureStoragePlugin.set({
        key: 'refreshToken',
        value: newRefreshToken,
    }).catch((error) => {
        console.log(error);
    });

    accessToken.value = newAccessToken;
    refreshToken.value = newRefreshToken;
}

export async function login(token: string) {
    if (Capacitor.getPlatform() != 'web') {
        const vpnActive = await VpnDetector.isVpnActive();
        if (vpnActive.value) {
            basicAlert('VPN Detected', '', 'Please disable your VPN and try again.');
            return false;
        }
    }

    /*await SplashScreen.show({
        autoHide: false,
    });*/
    let value = false;
    const loading = await showLoading('Logging In...');
    try {
        const response = await AppAPI.generateTokens(token);
        if (response) {
            await updateAuthTokens(response.data.access, response.data.refresh);
            if (accessToken.value) {
                value = true;
            }
        } else {
            errorToast('Magic link is expired. Get started to request a new one.');
        }
    } catch (error: any) {
        //console.log('Get JWT Error:', error);
        const message = error.response.data.error || 'There was an error logging in. Please try again.';
        if (message === 'Invalid token') {
            basicAlert(
                'Magic Link Expired',
                '',
                'The magic link has expired. Please request a new link to proceed with your login, or use an alternative link if available.'
            );
        } else {
            basicAlert('Login Error', '', message);
        }
    } finally {
        //await SplashScreen.hide();
        hideLoading(loading);
    }
    return value;
}

export async function refresh() {
    const response = await AppAPI.refreshToken(refreshToken.value).catch((error) => {
        console.log('refresh error', error);
        return null;
    });
    if (response?.data.access) {
        await updateAuthTokens(response.data.access, response.data.refresh);
        return true;
    } else {
        return false;
    }
}

export async function logout(NoRedirect = false, noAPI = false) {
    const store = useStore();
    if (store.isOffline) {
        return;
    }
    if (!noAPI) {
        await AppAPI.deactivateDevice(store.pushNotificationToken);
    }
    await updateAuthTokens('', '');
    await store.clear();
    if (!NoRedirect) {
        router.replace('/tabs/');
    }
}
