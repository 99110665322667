import AppFunctions from '@/services/AppFunctions';
import { errorToast } from '@/services/ToastController';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { defineStore } from 'pinia';
import { watch } from 'vue';
import { lodgixUrl, stagingUrl } from '@/constants';

export interface pushNotificationListType {
    id: string;
    title: string;
    message: string;
    read_status: string;
    created: string;
    archived: boolean;
}

export const useStore = defineStore('useStore', {
    state: () => ({
        URL_BASE: process.env.VUE_APP_URL_BASE,
        appName: 'Staylio',
        user: {} as any,
        selectedReservationId: '',
        selectedTechReservationId: '',
        reservationData: {} as any,
        allReservations: [] as any,
        pushNotificationToken: '',
        pushNotificationList: [] as pushNotificationListType[] | undefined,
        isPushNotificationsEnabled: false,
        reviewStatus: '',
        hostedForms: [] as any,
        openInMapsAddress: '',
        hasPushNotificationReminderSent: false,
        pushNotificationReminderHidden: false,
        scrollToBottomCounter: 0,
        viewingChatWithoutReservation: false,
        noReservationChatConversationId: '',
        currentConversationSubject: '',
        isBetaUser: false,
        showArchived: false,
        isOffline: false,
        isDemo: false,
    }),

    getters: {
        isDevelopment: (state) => {
            if (state.URL_BASE != lodgixUrl && state.URL_BASE != stagingUrl) {
                return true;
            } else {
                return false;
            }
        },
        isStaging: (state) => {
            if (state.URL_BASE == stagingUrl) {
                return true;
            } else {
                return false;
            }
        },
        isProduction: (state) => {
            if (state.URL_BASE == lodgixUrl) {
                return true;
            } else {
                return false;
            }
        },
        connectionUrl: (state) => {
            return state.URL_BASE;
        },
        isLocalDevelopment: (state) => {
            if (state.URL_BASE != lodgixUrl && state.URL_BASE != stagingUrl) {
                return true;
            } else {
                return false;
            }
        },
    },

    actions: {
        async changeSelectedReservationId(newValue: any) {
            await SecureStoragePlugin.set({ key: 'selectedResId', value: newValue.toString() }).catch((error) => {
                console.error(error);
            });
            this.selectedReservationId = newValue;
            if (this.selectedReservationId) {
                this.noReservationChatConversationId = '';
                this.viewingChatWithoutReservation = false;
                await AppFunctions.updatePushNotificationList();
            }
        },
        async clear() {
            await SecureStoragePlugin.clear();
            this.user = {};
            this.selectedReservationId = '';
            this.selectedTechReservationId = '';
            this.reservationData = {};
            this.allReservations = [];
            this.pushNotificationToken = '';
        },
        async updateReviewStatus(newValue: string) {
            SecureStoragePlugin.set({ key: 'reviewStatus', value: newValue });
            this.reviewStatus = newValue;
        },
        async updatepushNotificationReminderHidden(newValue: boolean) {
            if (newValue) {
                SecureStoragePlugin.set({ key: 'pushNotificationReminderState', value: 'true' });
                this.pushNotificationReminderHidden = true;
            } else {
                SecureStoragePlugin.set({ key: 'pushNotificationReminderState', value: 'false' });
                this.pushNotificationReminderHidden = false;
            }
        },
        async setBackupReservationData() {
            await SecureStoragePlugin.set({
                key: 'reservationData',
                value: JSON.stringify(this.reservationData),
            }).catch((error) => {
                console.error('reservationData', error);
            });
        },
        async getBackupReservationData() {
            const data = await SecureStoragePlugin.get({ key: 'reservationData' }).catch((error) => {
                console.error('reservationData', error);
            });
            if (data) {
                this.reservationData = JSON.parse(data.value);
                this.selectedReservationId = this.reservationData.id || '';
                this.selectedTechReservationId = this.reservationData.tech_reservations[0].id || '';
            }
        },
        async setAmenitiesEmbeddingData(data: any) {
            await SecureStoragePlugin.set({
                key: 'amenitiesEmbeddingData',
                value: JSON.stringify(data),
            }).catch((error) => {
                console.error('amenitiesEmbeddingData', error);
            });
        },
        async getAmenitiesEmbeddingData() {
            const data = await SecureStoragePlugin.get({ key: 'amenitiesEmbeddingData' }).catch((error) => {
                console.error('amenitiesEmbeddingData', error);
                return null;
            });
            if (data) {
                return JSON.parse(data.value);
            }
            return null;
        },
    },

    persist: {
        storage: sessionStorage,
    },
});
