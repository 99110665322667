import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_modal_base_layout = _resolveComponent("modal-base-layout")!

  return (_openBlock(), _createBlock(_component_modal_base_layout, { title: "Amenities" }, {
    header: _withCtx(() => [
      _createVNode(_component_ion_searchbar, {
        placeholder: "Search",
        debounce: 250,
        onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event))),
        "show-clear-button": "focus",
        style: {"text-align":"left","padding":"0px"}
      })
    ]),
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_ion_spinner, {
            key: 0,
            name: "crescent",
            style: {"margin-top":"50px"}
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ion_list, { lines: "full" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.resultEmbedding, (result) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: result.amenity
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(result.amenity), 1)
                        ]),
                        _: 2
                      }, 1024),
                      false
                        ? (_openBlock(), _createBlock(_component_ion_note, {
                            key: 0,
                            slot: "end"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(result.similarity), 1)
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]))
    ]),
    _: 1
  }))
}