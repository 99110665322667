<template>
    <div class="login-warning-layout">
        <img src="./../assets/nameLogo.png" style="height: 150px; margin-bottom: 30px" />
        <ion-button @click="loginPage()">Get Started</ion-button>
    </div>
</template>

<script lang="ts">
    import { useIonRouter } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { useStore } from '@/store/store';
    import { bonfire } from 'ionicons/icons';

    export default defineComponent({
        components: {},
        setup() {
            const store = useStore();
            const ionRouter = useIonRouter();

            const loginPage = async () => {
                ionRouter.replace('/login');
            };

            const url = store.URL_BASE;

            return {
                ionRouter,
                store,
                url,
                bonfire,
                loginPage,
            };
        },
    });
</script>

<style scoped>
    .login-warning-layout {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        background: var(--ion-color-light);
        color: var(--ion-color-light);
        padding: 20px;
        position: fixed;
        overflow: hidden;
        scroll-behavior: none;
        top: 0;
        width: 100%;
    }
    ion-button {
        width: 250px;
        height: 50px;
        font-size: 17px;
        font-weight: 700;
        margin: 10px;
    }
    ion-label {
        margin-bottom: 50px;
        font-size: 25px;
        padding-bottom: 5px;
        font-weight: bold;
        color: var(--ion-color-primary);
        border-bottom: 2px solid var(--ion-color-primary);
    }
</style>
